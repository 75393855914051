import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './technologySection.module.scss';
import ArrowRight from "../../../images/icons/arrow_right_green_fill.svg";
import DcodeLogo from "../../../images/logos/dcode_logo.svg";
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";
import WideSection from "../../shared/WideSection/WideSection";
import Text from "../../shared/Text/Text";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_technology"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const TechnologySection = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const renderSection = (index, imgScale = 1, imageAlt, text, image, doubleSize = false) => {
        const doubleSizeClass = doubleSize ? css.doubleSize : '';
        return (
            <div className={[css.section, doubleSizeClass, css[`section${index}`]].join(' ')}>
                <div className={css.sectionImageWrapper}>
                    {renderImageByFileName(imagesFluid, image, imageAlt)}
                </div>
                <div className={css.sectionText}>
                    <div className={css.sectionTextInner}>
                        {text}
                    </div>
                </div>
            </div>
        );
    };


    const text = <> Existing timber log marking and identification processes using plastic, metal, chemical (paint) or
        electronic components, highly damage-prone (abrasion, mechanical defects, and atmospheric impacts) &
        tamper-prone methods.
        <br/><br/>
        Dcode marking method is cost-effective by complete elimination of consumables required for tag production or
        direct marking (paint, RFID, pre-printed plastic tags with barcodes, DataMatrix codes, QR codes, and others).
        <br/><br/>
        Dcode marking method is eco and environmentally friendly.
    </>

    const content = <div className={css.wrapper}>
        <div className={css.wrapperInner}>
            <div className={css.sectionsWrapper}>
                <div className={css.old}>
                    <div className={css.groupHeader}>Existing solutions</div>
                    {renderSection(1, 1, "RFID", "RFID", 'RFID_Technology.png')}
                    {renderSection(2, 1, "paint", "Paint", 'Paint_Technology.png')}
                    {renderSection(3, 1, "tags", "Tags", 'Tags_Technology.png')}
                    {renderSection(4, 1, "metal", "Metal", 'Metal_Technology.png')}
                    {renderSection(5, 1, "electronic", "Electronic", 'Electronic_Technology.png')}
                    {renderSection(6, 1, "foil", "Foil", 'Foil_Technology.png')}
                </div>
                <div className={css.arrow}>
                    <img src={ArrowRight} alt=""/>
                </div>
                <div className={css.new}>
                    <div className={css.groupHeader}>Invention</div>
                    {renderSection(7, 1, "Dcode", <img src={DcodeLogo} alt=""/>, 'Dcode_Technology.png', true)}
                </div>
            </div>
        </div>
    </div>

    return (
        <WideSection
            title={''}
            bigContent={<div className={css.bigContent}>
                {content}
            </div>}
            smallContent={<div className={css.smallContent}>
                {content}
            </div>}
            text={<Text textIsBold={false}>{text}</Text>}
            blackBackground={true}
        />
    );
};

export default TechnologySection;
