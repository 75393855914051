import React from 'react';
import {useTranslation} from 'react-i18next';
import css from './devices.module.scss';
import LinkButton from "../../shared/LinkButton/LinkButton";
import {getRoutes} from "../../shared/routes";
import {graphql, useStaticQuery} from "gatsby";
import {getImagesFluid, renderImageByFileName} from "../../componentsUtils";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_devices"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const ElectronicDevices = () => {
    const {t} = useTranslation();
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const renderSection = (imgScale = 1, imageAlt = '', title, image, route) => {
        return (
            <div className={css.section}>
                <div className={css.sectionImageWrapper} style={{width: `${100 * imgScale}%`}}>
                    {renderImageByFileName(imagesFluid, image, imageAlt)}
                </div>
                <div className={css.sectionContentWrapper}>
                    <div className={css.sectionTitle}>
                        {title}
                    </div>
                    <LinkButton label={'Learn more'} route={route}/>
                </div>
            </div>
        );
    };

    const routes = getRoutes();
    return (
        <div className={css.wrapper}>
            <div className={css.wrapperInner}>
                <div className={css.header}>
                    <div className={css.title}>
                        Electronic Devices
                    </div>
                </div>
                <div className={css.sectionsWrapper}>
                    {renderSection(0.95, "Electronic Dcode Generator", <>Electronic Dcode
                        Generator</>, 'EDG_2.png', routes.TechnologyEDG)}
                    {renderSection(0.4, "Optical Dcode Reader", <>Optical Dcode
                        Reader</>, 'cameras.png', routes.TechnologyODR)}
                </div>
            </div>
        </div>
    );
};

export default ElectronicDevices;
