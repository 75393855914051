import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import {getRoutes} from '../components/shared/routes';
import MobileApps from "../components/sections/mobile_apps/MobileApps";
import Saas from "../components/sections/saas/Saas";
import Devices from "../components/sections/Devices/Devices";
import SolutionsSplash from "../components/sections/SolutionsSplash/SolutionsSplash";
import AdditionalInformation from "../components/shared/additionalInformation/additionalInformation";
import PageTitle from "../components/shared/PageTitle/PageTitle";
import TechnologySection from "../components/sections/TechnologySection/TechnologySection";
import ElectronicDevices from "../components/sections/Devices/ElectronicDevices";

const Technology = () => {
    const routes = getRoutes().Technology;
    const {sections} = routes;
    const pageTitle = routes.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <PageTitle>{pageTitle}</PageTitle>

            <SolutionsSplash/>

            <TechnologySection/>

            <div id={routes.sections.saas.anchor}>
                <Saas/>
            </div>

            <div id={routes.sections.mobileApps.anchor}>
                <MobileApps/>
            </div>

            <div id={sections.markers.anchor}>
                <Devices/>
            </div>

            <div id={sections.electronicDevices.anchor}>
                <ElectronicDevices/>
            </div>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default Technology;
